<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">Schedule</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- Table Container Card -->
    <b-card no-body>
      <b-tabs class="m-2" lazy>
        <b-tab active>
          <template #title>
            <b-img
              :src="require('@/assets/images/svg/candling.svg')"
             style="margin-right: 7px; width: 20px"
            />
            <span>Candling Schedule</span>
          </template>
          <candling-list />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <b-img
              :src="require('@/assets/images/svg/vaccination-sch.svg')"
             style="margin-right: 7px; width: 20px"
            />
            <span>Vaccination Schedule</span>
          </template>
          <VaccinationList />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BTab,
  BCardText,
  BFormGroup,
  BFormSelect,
  BFormDatepicker,
  BImg
} from "bootstrap-vue";

import vSelect from "vue-select";
import CandlingList from "./CandlingList.vue";
import VaccinationList from "./VaccinationList.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BFormGroup,
    BTooltip,
    BCardText,
    BTabs,
    BTab,
    vSelect,
    BFormSelect,
    BFormDatepicker,
    CandlingList,
    VaccinationList,
	BImg
  },
  data: () => ({
    selected: null,

    options: [
      { value: null, text: "" },
      { value: "a", text: "1" },
      { value: "b", text: "2" },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
