<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="d-flex justify-content-center align-items-center">
        <!-- Date picker -->
        <b-col cols="12" md="7">
          <b-row>
            <b-col md="5 p-0 ">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.from_date"
              />
            </b-col>
            <b-col md="5 ">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.to_date"
              />
            </b-col>
            <b-col md="2 p-0 d-flex align-items-end">
              <b-button @click="getCandlingSchedule()" variant="success ">
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="5"
          class="mt-2 d-flex justify-content-end align-items-center"
        >
          <div>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search"
              @input="getCandlingSchedule()"
              v-model.trim="search_filter.input_field"
              style="border-radius: 4px"
            />
          </div>
          <b-button
            @click="clearFilter"
            variant="secondary
 			 ml-1"
          >
            Clear
          </b-button>
          <b-button v-b-modal.candling-schedule variant="primary mr-1 ml-1">
            Add
          </b-button>
          <b-button @click="excelDownload()" variant="warning">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="candlingSchedules && candlingSchedules.total > 0">
      <b-table
        :items="candlingSchedules.data"
        responsive
        :fields="fields"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <template #cell(index)="data">
          <div class="ml-1">
            {{ candlingSchedules.from + data.index }}
          </div>
        </template>
        <template #cell(candling_status)="data">
          <div v-if="data.value === 'Active'" class="activeClass">Active</div>
          <div v-else class="inactiveClass">Inactive</div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              size="16"
              v-b-modal.edit-candling-schedule
              @click="() => getCandlingById(data.item.id)"
            />

            <b-tooltip
              title="Edit"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />

            <feather-icon
              @click="() => setCandlingId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-delete-candling
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-pagination
          :value="candlingSchedules.current_page"
          :total-rows="candlingSchedules.total"
          :per-page="candlingSchedules.per_page"
          align="right"
          @change="getCandlingSchedule"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img
        :src="
          require('@/assets/images/svg/infographics/candling_infographic.svg')
        "
        class="mr-2"
      />
      <h4 class="mt-2 Secondary">No candling schedule added yet</h4>
    </div>
    <!--  modal -->
    <b-modal
      id="candling-schedule"
      ok-only
      ok-title="Submit"
      @ok="addCandlingSchedule"
      ref="my-modal"
      title="Add Candling Schedule"
      @show="resetModal"
      @hidden="resetModal"
    >
      <validation-observer ref="simpleRules">
        <b-form ref="form" @submit="addCandlingSchedule">
          <b-form-group>
            <template v-slot:label>
              Enter Candling <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Candling Name"
              rules="required"
            >
              <b-form-input
                name="candling_name"
                v-model="candlingScheduleData.name"
                placeholder="Enter Candling Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <template v-slot:label>
              Enter From Day<span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="From Day"
              :rules="{
                required: true,
                integer: true,
                min_value: 1,
                max_value: 18,
              }"
            >
              <b-form-input
                name="from_day"
                v-model="candlingScheduleData.from_day"
                placeholder="Enter From Day"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Enter To Day<span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="To Day"
              :rules="{
                required: true,
                integer: true,
                min_value: candlingScheduleData.from_day,
                max_value: 18,
              }"
            >
              <b-form-input
                name="to_day"
                v-model="candlingScheduleData.to_day"
                placeholder="Enter To Day"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- edit candlingSchedule -->
    <b-modal
      id="edit-candling-schedule"
      ok-only
      ok-title="Submit"
      @ok="updateCandling"
      ref="my-modal"
      title="Edit Candling Schedule"
    >
      <validation-observer ref="simpleRules">
        <b-form ref="form" @submit="updateCandling">
          <b-form-group>
            <template v-slot:label>
              Enter Candling Name<span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Candling Name"
              rules="required"
            >
              <b-form-input
                name="candling_name"
                v-model="editCandlingData.name"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <template v-slot:label>
              Enter From Day<span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="From Day"
              rules="required"
            >
              <b-form-input
                name="from_day"
                v-model="editCandlingData.from_day"
                placeholder=""
                :disabled="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Enter To Day<span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="To Day"
              rules="required"
            >
              <b-form-input
                name="to_day"
                v-model="editCandlingData.to_day"
                placeholder=""
                :disabled="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Status" label-for=" status">
            <div class="d-flex mt-1">
              <b-form-radio
                v-model="editCandlingData.candling_status"
                name="status"
                value="Active"
                class="mr-1"
                >Active</b-form-radio
              >
              <b-form-radio
                v-model="editCandlingData.candling_status"
                name="status"
                value="Inactive"
                >InActive</b-form-radio
              >
            </div>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- delete modal -->
    <DeleteModal
      id="modal-delete-candling"
      :onClicked="deleteCandlingSchedule"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BTab,
  BCardText,
  BFormGroup,
  BFormSelect,
  BFormRadio,
  BFormDatepicker,
} from "bootstrap-vue";

import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email, min_value, max_value, integer } from "@validations";
export default {
  components: {
    BCard,
    BRow,
    BImg,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BFormGroup,
    BTooltip,
    DeleteModal,
    BCardText,
    BTabs,
    BTab,
    BFormRadio,
    vSelect,
    BFormSelect,
    BFormDatepicker,
  },
  data: () => ({
    value: "",
    selected: null,
    isSortDirDesc: null,
    searchQuery: null,
    sortBy: null,
    candlingSchedules: {},
    candlingScheduleData: {
      name: null,
      from_day: null,
      to_day: null,
    },
    search_filter: {
      input_field: "",
      to_date: "",
      from_date: "",
    },
    editCandlingData: {
      candling_name: null,
    },
    fields: [
      { key: "index", label: "No" },
      "name",
      "from_day",
      "to_day",
      { key: "candling_status", label: "candling status" },
      {
        key: "created_at",
        label: "created at",
        formatter: (value) => {
          return moment(value).format("MMMM Do YYYY");
        },
      },
      "Actions",
    ],
  }),
  methods: {
    resetModal() {
      this.candlingScheduleData.name = null;
      this.candlingScheduleData.from_day = null;
      this.candlingScheduleData.to_day = null;
    },
    getCandlingSchedule(pageNo = 1) {
      const companyId = this.$route.params.companyId;
      let url = "";
      if (companyId) {
        url = `web/company/${companyId}/candling-schedule`;
      } else {
        url = `web/candling-schedule`;
      }
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }
      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.candlingSchedules = response.data.candlingScheduleList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    setCandlingId(id) {
      this.editCandlingData.id = id;
    },
    getCandlingById(id) {
      axiosIns
        .get(`web/candling-schedule/${id}`)
        .then((response) => {
          this.editCandlingData = response.data.candlingSchedule;
          this.editCandlingData.id = id;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    updateCandling(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        var formData = new FormData();
        this.editCandlingData.name = this.editCandlingData.name;
        this.editCandlingData.method = "PUT";
        let form_data = this.objectToFormData(this.editCandlingData, formData);
        form_data.set("_method", "PUT");
        axiosIns
          .post(`web/candling-schedule/${this.editCandlingData.id}`, form_data)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                icon: "CoffeeIcon",
                variant: "success",
                text: `Candling Schedule updated `,
              },
            });
            this.$refs["my-modal"].hide();
            this.getCandlingSchedule();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              var data = error.response.data;
            } else {
              var data = error.response.data.errors;
            }
            let arr = Object.values(data).flat().toString(",");

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                icon: "CoffeeIcon",
                variant: "danger",
                text: arr,
              },
            });
          });
      });
    },
    addCandlingSchedule(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          this.candlingScheduleData.hatchery_id = this.$route.params.hatcheryId;
          this.candlingScheduleData.company_id = this.$route.params.companyId;
          let form_data = this.objectToFormData(
            this.candlingScheduleData,
            formData
          );

          axiosIns
            .post(`web/candling-schedule`, form_data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Successfully Added `,
                },
              });
              this.getCandlingSchedule();
              this.$bvModal.hide("candling-schedule");
            })
            .catch((error) => {
              if (error.response.status == 400) {
                var data = error.response.data;
              } else {
                var data = error.response.data.errors;
              }
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },

    deleteCandlingSchedule(id) {
      var data = new FormData();
      data.append("_method", "DELETE");
      axiosIns
        .post(`web/candling-schedule/${this.editCandlingData.id}`, data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Data Successfully Deleted `,
            },
          });
          this.$refs["my-modal"].hide();
          this.getCandlingSchedule();
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
          this.$refs["my-modal"].hide();
          this.getCandlingSchedule();
        });
    },

    excelDownload() {
      if (this.search_filter.from_date && this.search_filter.to_date) {
        this.search_filter.from_date = moment(
          this.search_filter.from_date
        ).format("YYYY-MM-DD");
        this.search_filter.to_date = moment(this.search_filter.to_date).format(
          "YYYY-MM-DD"
        );
      }
      let order_filter_data = this.prepareForm(this.search_filter);
      axiosIns
        .post(`web/export-candling-schedule`, order_filter_data)
        .then((response) => {
          var tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = response.data.file;
          tempLink.setAttribute("download", response.data.name);
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getCandlingSchedule();
        })
        .catch((error) => {
          error.response;
          const data = error.response.data.errors;
          data, "data";
          let arr = Object.values(data).flat().toString(",");
          arr, "array";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: arr,
            },
          });
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getCandlingSchedule();
        });
    },
    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getCandlingSchedule();
    },
  },

  created() {
    this.getCandlingSchedule();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.activeClass {
  background-color: #38c06c;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  justify-content: center;
}
.inactiveClass {
  background-color: #ea5455;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  justify-content: center;
  border-radius: 10px;
}
.header-title {
  height: 60px;
  border-bottom: 1px solid #ebe9f1;
  font-weight: 500;
  color: #636363;
  font-size: 1.714rem;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
}

.invoice-filter-select {
  min-width: 190px;

  // ::v-deep .vs__selected-options {
  // 	flex-wrap: nowrap;
  // }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
