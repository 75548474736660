<template>
  <div>
    <!-- delete modal -->
    <b-modal
      :id=id
      ok-only
      ok-variant="danger"
      ok-title="OK"
      modal-class="modal-danger"
      title="Delete Data"
      @ok="() => onClicked()"
    >
      <b-card-text> Are you sure you want to Delete Data ? </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BModal,
  },
  props: {
    onClicked: Function,
    id: String,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>